export const project = {
    version: '2.1.0',
    buildNumber: '0',
    mobileApiPath: 'sbg-mobile/rest'
};

export const scopes = {
    PLATFORM_ALL: 'sbg-platform.all',
    CORE_CUSTOMER_PLATFORM: 'sbg-corecustomerplatform.all',
    PAYMENTS: 'sbg-payment.all',
    OPEN_ID: 'openid',
    PROFILE: 'profile',
    DEVICE: 'device',
    EMAIL: 'email',
    CARDS: 'card-domain.*.*',
    SURVEY: 'surveyservice.*.*',
    MESSAGING: 'messaging.*.*',
    PING_IDENTITY: 'urn:pingidentity:directory-api'
};
export const channel = 'SBG_WEB';

export const firebaseConfig = {
    apiKey: 'AIzaSyAxNoiuHQvwvdms9JYOna7wtl9S-VWNJ_U',
    authDomain: 'nobi-403d2.firebaseapp.com',
    databaseURL: 'https://nobi-403d2-default-rtdb.firebaseio.com',
    projectId: 'nobi-403d2',
    storageBucket: 'nobi-403d2.appspot.com',
    messagingSenderId: '905676329994',
    appId: '1:905676329994:web:126b92ca71e34c5dbbfbf3',
    measurementId: 'G-MY0Y19LE2Y'
};
